import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { PoppedOutEditorViewer } from '../components/PoppedOutEditorViewer';
import Loading from '../components/Loading';
import { useSelector } from '../store';
import EditorActions from '../actions/EditorActions';
import { BroadcastActions } from '../components/BroadcastActions';
import { PaneSplitterContext } from '../components/projects/edit/PaneSplitter';
import { paneSplitterActions } from '../features/paneSplitter/paneSplitterSlice';
import { PopoutButton } from '../components/projects/edit/PopoutButton';

export function PoppedOutEditorViewerContainer() {
  const state = useSelector((state) => state.editor);
  const paneSplitterState = useSelector((state) => state.paneSplitter);
  const dispatch = useDispatch();

  // Store initial URL to identify panes
  const locationHref = useMemo(() => window.location.href, []);

  useEffect(() => {
    // PDFのURLが期限切れになっていると表示できないので、新しいURLを取ってくる
    EditorActions.fetchCompileResult();
  }, [!!state.project]);

  const onBeforeUnload = useCallback(() => {
    dispatch(paneSplitterActions.popinPane({ url: locationHref }));
    // beforeunloadが複数回発生するので一度実行したら外す
    window.removeEventListener('beforeunload', onBeforeUnload);
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, [onBeforeUnload]);

  const onPdfLoaded = useCallback(() => {
    EditorActions.onPdfLoaded();
  }, []);

  const onPdfLoadStarted = useCallback(() => {
    EditorActions.onPdfLoadStarted();
  }, []);

  const invalidateFileCacheHandler = useCallback(() => {
    EditorActions.invalidateFileCache();
  }, []);

  const openInvalidateFileCacheDialog = useCallback((open: boolean) => {
    EditorActions.openInvalidateFileCacheDialog(open);
  }, []);

  if (state.project && !paneSplitterState.popoutPanes.includes(locationHref)) {
    // windowを閉じると通信中のリクエストがキャンセルされ、offlineのアクションが送信されてしまうので
    // 先にactionの同期を切ってからcloseする
    setTimeout(() => {
      window.removeEventListener('beforeunload', onBeforeUnload);
      window.close();
    }, 100);
  }

  const emulatedContext = useMemo(
    () => ({
      leftButton: null,
      rightButton: <PopoutButton poppedOut onClick={onBeforeUnload} />,
      adjustedInitialWidth: null,
    }),
    [onBeforeUnload],
  );

  return (
    <>
      <BroadcastActions
        closed={
          state.project && !paneSplitterState.popoutPanes.includes(locationHref)
        }
      />
      {state.project ? (
        <PaneSplitterContext.Provider value={emulatedContext}>
          <PoppedOutEditorViewer
            compiling={state.compiling}
            result={state.result}
            texDocSearching={state.texDocSearching}
            documentResult={state.documentResult}
            pdfPageInfo={state.pdfPageInfo}
            showSynctexPdfIndicator={state.showSynctexPdfIndicator}
            tabValue={state.tabValue}
            project={state.project}
            showPdfViewLoading={state.showPdfViewLoading}
            invalidateFileCacheHandler={invalidateFileCacheHandler}
            openInvalidateFileCacheDialog={openInvalidateFileCacheDialog}
            pdfLoading={state.pdfLoading}
            onPdfLoaded={onPdfLoaded}
            onPdfLoadStarted={onPdfLoadStarted}
          />
        </PaneSplitterContext.Provider>
      ) : (
        <Loading />
      )}
    </>
  );
}
