import './globals';
// import jQuery from 'jquery';
// window['jQuery'] = window['$'] = jQuery;

import 'jquery-ui';
import 'jquery-ui/ui/widgets/droppable';
import * as RailsUJS from '@rails/ujs';
import * as React from 'react';
import { Store } from 'redux';
import { Provider, ConnectedComponent } from 'react-redux';

import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { editorStore } from './store';
import 'blueimp-file-upload/js/jquery.fileupload';
import { t } from './i18n';
import './video';
import '../cms';

import HomeApp from './components/home/HomeApp';
import ProjectsAppCtrl from './components/projects/ProjectsAppCtrl';
import EditorAppContainer from './containers/EditorAppContainer';
import SignedInHeaderCtrl from './components/SignedInHeaderCtrl';
import PopUpFlash from './components/PopUpFlash';
import Notification from './components/Notification';
import { PromotionApp } from './components/PromotionApp';

import 'bootstrap-sass/assets/javascripts/bootstrap';
import 'bootstrap-material-design/scripts/ripples';
import 'bootstrap-material-design/scripts/material';
import { TemplateTagsInput } from './components/TemplateTagsInput';
import { PoppedOutEditorViewerContainer } from './containers/PoppedOutEditorViewerContainer';

declare let window: any;

Sentry.init(window.sentryInfo);
Sentry.setUser(window.sentryUserInfo);

window.React = React; // export for http://fb.me/react-devtools
// window['Perf'] = require('react-addons-perf');

RailsUJS.start();

type ComponentType =
  | React.ComponentClass<any, any>
  | ConnectedComponent<any, any>
  | React.FunctionComponent<any>;

class ReactAppEntry {
  elementId: string;

  component: ComponentType;

  store?: Store;

  constructor(elementId: string, component: ComponentType, store?: Store) {
    this.elementId = elementId;
    this.component = component;
    this.store = store;
  }
}
$().ready(() => {
  const reactApps = [
    new ReactAppEntry('top_nav', HomeApp),
    new ReactAppEntry('projects_app', ProjectsAppCtrl),
    new ReactAppEntry('app', EditorAppContainer, editorStore),
    new ReactAppEntry('signed_in_header', SignedInHeaderCtrl),
    new ReactAppEntry('promotion', PromotionApp),
    new ReactAppEntry(
      'editor_viewer',
      PoppedOutEditorViewerContainer,
      editorStore,
    ),
  ];
  reactApps.forEach((reactApp) => {
    const elem = document.getElementById(reactApp.elementId);
    if (!elem) {
      return;
    }
    const props = elem.getAttribute('data-react-props');
    elem.removeAttribute('data-react-props');
    let reactElement;
    if (props) {
      try {
        const propsObject = JSON.parse(props);
        reactElement = React.createElement(reactApp.component, propsObject);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Error while rendering ${reactApp.elementId}.`, e);
      }
    } else {
      reactElement = React.createElement(reactApp.component);
    }
    if (reactApp.store) {
      reactElement = React.createElement(
        Provider,
        { store: reactApp.store },
        reactElement,
      );
    }
    ReactDOM.render(reactElement, elem);
  });

  try {
    const elements = document.querySelectorAll(
      '[data-react-class=TemplateTagsInput]',
    );
    for (const elem of elements) {
      const props = elem.getAttribute('data-react-props');
      elem.removeAttribute('data-react-props');
      ReactDOM.render(
        React.createElement(TemplateTagsInput, JSON.parse(props)),
        elem,
      );
    }
  } catch {
    // ignore
  }

  if (document.getElementsByTagName('flash')[0]) {
    const flash = document.getElementsByTagName('flash')[0];
    ReactDOM.render(
      React.createElement(PopUpFlash, { message: flash.getAttribute('value') }),
      flash,
    );
  }
  if (document.getElementById('alert')) {
    const alert = document.getElementById('alert');
    ReactDOM.render(
      React.createElement(Notification, {
        message: alert.getAttribute('value'),
      }),
      alert,
    );
  }
  if (!$('.no_bootstrap_js').length) {
    (<any>$).material.init();
    $(document).bind('DOMNodeInserted', (event) => {
      (<any>$).material.init();
    });
  }
  if ($('.sortable_js').length) {
    import('jquery-ui/ui/widgets/sortable');
  }
});
