import { Component } from 'react';
import SignedInHeader from './SignedInHeader';
import ExtensionDialog from './ExtensionDialog';
import UserStore from '../stores/UserStore';
import UserActions from '../actions/UserActions';
import { withErrorBoundaryFallback } from './error/withErrorBoundaryFallback';
import { withRootTheme } from './withRootTheme';

interface State {
  user: {
    name: string;
    email: string;
    state: number;
  };
  dropbox_linked: boolean;
  unlinking: boolean;
  oauthDisabled: boolean;
  extension: any;
  extensionDialogOpend: boolean;
  loadUserFailed: boolean;
}
class SignedInHeaderCtrl extends Component<{}, State> {
  constructor(props) {
    super(props);
    this.state = {
      user: window['user'],
      dropbox_linked: UserStore.getStates().dropbox_linked,
      unlinking: false,
      oauthDisabled: false,
      extensionDialogOpend: false,
      extension: {},
      loadUserFailed: false,
    };
    this._onChange = this._onChange.bind(this);
    this.onChangeOauthDisabled = this.onChangeOauthDisabled.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props !== nextProps || this.state !== nextState;
  }

  render() {
    return (
      <div>
        <SignedInHeader
          onChangeOauthDisabled={this.onChangeOauthDisabled}
          onFinishOauth={UserActions.loadUserInfo}
          openExtensionDialog={() => this.setExtensionDialog(true)}
          {...this.state}
        />
        <ExtensionDialog
          open={this.state.extensionDialogOpend}
          onClose={this.onClose}
          token={this.state.extension.token}
          client={this.state.extension.client}
          errors={this.state.extension.errors}
          successed_to_generate={this.state.extension.successed_to_generate}
          failed_to_generate={this.state.extension.failed_to_generate}
          successed_to_delete={this.state.extension.successed_to_delete}
          failed_to_delete={this.state.extension.failed_to_delete}
          generateToken={this.generateToken.bind(this)}
          deleteTokens={this.deleteTokens.bind(this)}
        />
      </div>
    );
  }

  componentDidMount() {
    UserStore.addChangeListener(this._onChange);
    UserActions.loadUserInfo().catch(() => {
      this.setState({ loadUserFailed: true });
    });
  }

  componentWillUnmount() {
    UserStore.removeChangeListener(this._onChange);
  }

  _onChange() {
    const storeStates = UserStore.getStates();
    this.setState({
      dropbox_linked: storeStates.dropbox_linked,
      unlinking: storeStates.unlinking,
      extension: storeStates.extension,
    });
  }

  onChangeOauthDisabled(state) {
    this.setState({ oauthDisabled: state });
  }

  onClose() {
    this.setExtensionDialog(false);
    this.setState({ extension: {} });
  }

  generateToken(password: string) {
    return UserActions.generateExtensionToken(this.state.user.email, password);
  }

  deleteTokens() {
    return UserActions.deleteExtensionTokens();
  }

  setExtensionDialog(opend = true) {
    this.setState({
      extensionDialogOpend: opend,
    });
  }
}

export default withErrorBoundaryFallback(withRootTheme(SignedInHeaderCtrl));
