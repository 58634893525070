// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  CommentContent = 1,
  blockOptionName = 86,
  mismatchedBlockOptionName = 87,
  commentBlockOptionName = 88,
  Content = 2,
  LineComment = 3,
  Document = 4,
  Section = 5,
  TagSection = 6,
  TagName = 9,
  Option = 10,
  String = 16,
  ModuleName = 22,
  Atom = 33,
  Block = 40,
  TagBegin = 41,
  TagEnd = 50,
  MismatchedBlock = 53,
  MismatchedTagEnd = 55,
  CommentBlock = 58,
  CommentTagBegin = 59,
  CommentTagEnd = 63,
  Math = 69,
  MathVariable = 72,
  MathTagName = 73,
  MathContent = 74,
  MathNumber = 75,
  Keyword = 77
