import { withStyles } from '@material-ui/core/styles';
import { Icon, Tooltip } from '@material-ui/core';
import { t } from '../../../i18n';
import IconButtonEx from './IconButtonEx';

const ColoredButton = withStyles((theme) => ({
  root: {
    borderRadius: 0,
    backgroundColor: theme.palette.background.paneDivider,
    '&:hover': {
      backgroundColor: theme.palette.background.paneDividerHover,
    },
    width: 36,
    float: 'right',
  },
}))(IconButtonEx);

export function PopoutButton({ poppedOut, onClick }) {
  const button = poppedOut
    ? {
        title: t(`view:editor.tool-tip.popin`),
        icon: 'call_received',
      }
    : {
        title: t(`view:editor.tool-tip.popout`),
        icon: 'open_in_new',
      };
  return (
    <Tooltip
      key="popout"
      title={<span style={{ fontSize: '14px' }}>{button.title}</span>}
      placement="bottom-end"
    >
      <ColoredButton
        onClick={onClick}
        disabled={false}
        children={<Icon style={{ fontSize: 18 }}>{button.icon}</Icon>}
      />
    </Tooltip>
  );
}
