import { useCallback, useEffect, useMemo, useRef } from 'react';
import { addListener, removeListener } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import EditorConstants from '../constants/EditorConstants';
import { editorStore } from '../store';
import { projectId } from '../utils/EditorWebAPIUtils';

function createChannel(): BroadcastChannel {
  return new BroadcastChannel('editor_viewer');
}

export function BroadcastActions({
  primary,
  closed,
}: {
  primary?: boolean;
  closed?: boolean;
}) {
  const dispatch: typeof editorStore.dispatch = useDispatch();
  const channelRef = useRef<BroadcastChannel>();

  useEffect(() => {
    const channel = createChannel();
    channelRef.current = channel;

    return () => {
      channel.close();
      channelRef.current = null;
    };
  }, []);

  useEffect(() => {
    // actionを送信する
    if (channelRef.current) {
      channelRef.current.onmessage = (event: MessageEvent) => {
        if (closed) {
          return;
        }

        if (projectId && event.data?.id === projectId) {
          if (event.data?.type === 'action') {
            if (
              primary &&
              event.data.action.type === EditorConstants.OVERWRITE
            ) {
              if (channelRef.current) {
                // new primary window is connected, so disconnect current window
                channelRef.current.close();
                channelRef.current = null;
              }
            } else {
              dispatch({ ...event.data.action, broadcast: true });
            }
          } else if (primary && event.data?.type === 'init') {
            // sync store
            channelRef.current.postMessage({
              id: projectId,
              type: 'action',
              action: {
                type: EditorConstants.OVERWRITE,
                payload: editorStore.getState(),
              },
            });
          }
        }
      };
      if (primary) {
        channelRef.current.postMessage({
          id: projectId,
          type: 'action',
          action: {
            type: EditorConstants.OVERWRITE,
            payload: editorStore.getState(),
          },
        });
      } else {
        channelRef.current.postMessage({ id: projectId, type: 'init' });
      }
    }
    return () => {
      if (channelRef.current) {
        channelRef.current.onmessage = null;
      }
    };
  }, [closed]);

  const listener = useMemo(
    () => ({
      predicate() {
        return true;
      },
      async effect(action, listenerApi) {
        // actionを他のウィンドウに飛ばす
        if (
          !closed &&
          channelRef.current &&
          !action.broadcast &&
          action.type !== EditorConstants.OVERWRITE
        ) {
          channelRef.current.postMessage({
            id: projectId,
            type: 'action',
            action,
          });
        }
      },
    }),
    [closed],
  );

  useEffect(() => {
    dispatch(addListener(listener));
    return () => {
      dispatch(removeListener(listener));
    };
  }, [dispatch, listener]);

  return null;
}
