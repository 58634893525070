import * as request from 'superagent';
import UserConstants from '../constants/UserConstants';
import AppDispatcher from '../dispatcher/AppDispatcher';
import { APIRoot, CSRFToken } from '../constants/CommonConstants';

const UserWebAPIUtils = {
  loadUserInfo() {
    return new Promise<void>((resolve, reject) => {
      request.get(`${APIRoot}/user`).end((error, res) => {
        try {
          if (res.status === 200) {
            const json = JSON.parse(res.text);
            resolve();
            AppDispatcher.dispatch({
              actionType: UserConstants.RECEIVE_USER_INFO,
              json,
            });
          } else {
            reject();
          }
        } catch (e) {
          reject();
        }
      });
    });
  },

  loadUserUsage() {
    return new Promise<void>((resolve, reject) => {
      request.get(`${APIRoot}/user/usage`).end((error, res) => {
        if (res.status === 200) {
          const json = JSON.parse(res.text);
          resolve();
          AppDispatcher.dispatch({
            actionType: UserConstants.RECEIVE_USER_USAGE,
            json,
          });
        } else {
          reject();
        }
      });
    });
  },

  unlinkRemoteStorage(remoteStorage) {
    return new Promise<void>((resolve, reject) => {
      request
        .post(`${APIRoot}/${remoteStorage}/unlink`)
        .set('X-CSRF-Token', CSRFToken())
        .end((error, res) => {
          if (res.status === 200) {
            resolve();
          } else {
            reject();
          }
        });
    });
  },

  generateExtensionToken(name_or_email: string, password: string) {
    return new Promise<any>((resolve, reject) => {
      request
        .post(`${APIRoot}/auth/sign_in`)
        .set('X-CSRF-Token', CSRFToken())
        .send({
          name_or_email,
          password,
        })
        .end((error, res) => {
          if (error && !res) {
            reject(error);
          }
          const json = JSON.parse(res.text);
          if (res.status === 200) {
            resolve({
              client: res.header.client,
              token: res.header['access-token'],
            });
          } else {
            reject(json);
          }
        });
    });
  },

  deleteExtensionTokens() {
    return new Promise<void>((resolve, reject) => {
      request
        .post(`${APIRoot}/user/delete_tokens`)
        .set('X-CSRF-Token', CSRFToken())
        .end((error, res) => {
          if (res.status === 200) {
            resolve();
          } else {
            reject();
          }
        });
    });
  },
};

export default UserWebAPIUtils;
