import * as React from 'react';
import { emacs } from '@replit/codemirror-emacs';
import {
  EditorView,
  highlightActiveLine,
  highlightActiveLineGutter,
  keymap,
} from '@codemirror/view';
import {
  historyField,
  redo,
  redoDepth,
  undo,
  undoDepth,
} from '@codemirror/commands';
import { autocompletion } from '@codemirror/autocomplete';

import { Icon, styled, Tooltip } from '@material-ui/core';
import * as _ from 'lodash';
import { Compartment, EditorState } from '@codemirror/state';
import { openSearchPanel } from '@codemirror/search';
import { setDiagnostics } from '@codemirror/lint';
import { vim } from '@replit/codemirror-vim';
import { Locator } from 'react/utils/synctex/locator';
import { FileSource } from 'react/constants/EditorConstants';
import EditorActions from 'react/actions/EditorActions';
import { getCodemirrorPhrases, t } from 'react/i18n';
import * as CL2Types from 'react/CL2Types';
import { spellCheckerPlugin } from 'codemirror/language/spellCheckerPlugin';
import { stex } from 'codemirror/language/stex';
import { themesList } from 'codemirror/theme';
import { cloudlatexDefault } from 'codemirror/theme/themes';
import { CSSProperties } from 'react';
import IconButtonEx from '../IconButtonEx';
import ConflictFileDialog from '../ConflictFileDialog';
import Loading from '../../../Loading';

import { PaneSplitterContext } from '../PaneSplitter';
import {
  ensureCompileTargetDependencies,
  stexCompletions,
  triggerOptionCompletion,
} from '../../../../utils/LatexHint';
import { basicSetup } from './codemirror';
import { EmptyPlaceholder } from './EmptyPlaceholder';

interface Props {
  style: React.CSSProperties;
  currentFile: CL2Types.CurrentFile;
  compileTargetFile: CL2Types.ProjectFile;
  waiting: boolean;
  loading: boolean;
  projectLoading: boolean;
  onChange: (newContent: string) => void;
  saveAndCompileHandler: () => void;
  isTargetFile: boolean;
  errors: Array<CL2Types.ResultError>;
  timestamp: number;
  conflictFileDialog: boolean;
  conflictFiles: Array<CL2Types.ConflictFile>;
  graphicsFiles: Array<CL2Types.ProjectFile>;
  textFiles: Array<CL2Types.ProjectFile>;
  texFiles: Array<CL2Types.ProjectFile>;
  suspendedRequests: Array<CL2Types.Request>;
  project: CL2Types.EditorProject;
  editorPositionInfo: CL2Types.EditorPositionInfo;
  showSynctexEditorIndicator: boolean;
  bibtexItems: CL2Types.BibtexItems;
}
interface State {
  mode: string;
  undoable: boolean;
  redoable: boolean;
  synctexScrolling: boolean;
}

const EditorToolbarDiv = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.toolbar,
  height: 36,
}));

export class FileEditor extends React.Component<Props, State> {
  private cursor: Record<string, number> = JSON.parse(
    localStorage.getItem('codeMirror6Cursor') || '{}',
  );

  private savedStates: Record<string, any> = {};

  private view = new EditorView();

  private locator = new Locator();

  private readonly editorRef: React.RefObject<HTMLDivElement>;

  private triedToLoadCompileTargetFile = false;

  editorPreference = new Compartment();

  constructor(props: Props) {
    super(props);
    this.state = {
      mode: 'stex',
      undoable: false,
      redoable: false,
      synctexScrolling: false,
    };
    this.editorRef = React.createRef();
  }

  extensionsFromEditorPreference() {
    return [
      themesList.find((t) => t.name === this.props.project?.editor_theme) ??
        cloudlatexDefault,
      EditorView.theme({
        '&': {
          fontSize: this.props.project?.font_size
            ? `${this.props.project.font_size}px`
            : undefined,
          height: '100%',
        },
        '.cm-scroller': {
          fontFamily: 'Inconsolata',
          height: '100%',
        },
      }),
      this.props.project?.enable_spell_check ? spellCheckerPlugin() : [],
      this.props.project?.key_bindings === 'emacs-like'
        ? emacs()
        : this.props.project?.key_bindings === 'vim-like'
          ? vim()
          : [],
      EditorState.phrases.of(getCodemirrorPhrases()),
      this.props.project?.permission.edit
        ? [highlightActiveLine(), highlightActiveLineGutter()]
        : [EditorState.readOnly.of(true), EditorView.editable.of(false)],
    ];
  }

  reconfigureEditorPreference() {
    this.view.dispatch({
      effects: [
        this.editorPreference.reconfigure(
          this.extensionsFromEditorPreference(),
        ),
        EditorView.scrollIntoView(this.view.state.selection.main.head),
      ],
    });
  }

  baseExtensions() {
    const compileKeymap = [
      {
        key: 'Ctrl-Enter',
        run: () => {
          this.props.saveAndCompileHandler();
          return true;
        },
      },
    ];

    return [
      this.editorPreference.of(this.extensionsFromEditorPreference()),
      EditorView.updateListener.of((update) => {
        if (update.docChanged) {
          this._updateHistoryStatus();
          this.props.onChange(this.view.state.doc.toString());
          // Use editor syntax tree when editing compile target
          if (this.props.currentFile?.id === this.props.compileTargetFile?.id) {
            ensureCompileTargetDependencies(this.view.state);
          }
        } else if (update.selectionSet) {
          if (!update.state.readOnly) {
            const transaction = update.transactions.find((tr) =>
              tr.isUserEvent('select.pointer'),
            );
            triggerOptionCompletion(update.view, !!transaction);
          }
        }
      }),
      EditorView.domEventObservers({
        dblclick: (e, view) => {
          const line = view.state.doc.lineAt(
            view.posAtCoords({
              x: e.clientX,
              y: e.clientY,
            }),
          ).number;
          EditorActions.synctexFromEditor(
            line,
            this.props.currentFile?.full_path,
            true,
          );
          return true;
        },
      }),
      EditorView.domEventHandlers({
        scroll: (e, view) => {
          this._onScroll();
        },
      }),
      autocompletion({
        override: [
          (context) =>
            stexCompletions(
              context,
              this.props.graphicsFiles,
              this.props.textFiles,
              this.props.texFiles,
              this.props.bibtexItems,
            ),
        ],
        addToOptions: [
          {
            render(completion, state, view) {
              if (completion.packages) {
                const e = document.createElement('span');
                e.innerText =
                  completion.packages?.length > 1
                    ? '...'
                    : completion.packages?.[0];
                e.classList.add('cm-completionPackageName');
                return e;
              }
              return null;
            },
            position: 81,
          },
        ],
        compareCompletions(a, b) {
          let i;
          for (i = 0; i < a.label.length; i += 1) {
            if (a.label[i] === '[' || a.label[i] === '{') break;
            if (!b.label[i] || b.label[i] === '[' || b.label[i] === '{')
              return 1;
            const signA = a.label[i] < 'A' || a.label[i] > 'z';
            const signB = b.label[i] < 'A' || b.label[i] > 'z';

            if (signA && !signB) {
              return 1;
            }
            if (signB && !signA) {
              return -1;
            }

            const compare = a.label[i].localeCompare(b.label[i]);
            if (compare !== 0) return compare;
          }
          if (b.label[i]) return -1;
          return 0;
        },
      }),
      keymap.of([...compileKeymap]),
      ...basicSetup,
    ];
  }

  setDocument(doc: string, savedState: any) {
    let initialState;
    if (savedState) {
      initialState = EditorState.fromJSON(
        { doc, ...savedState },
        { extensions: this.baseExtensions() },
        { history: historyField },
      );
    } else {
      initialState = EditorState.create({
        doc,
        extensions: this.baseExtensions(),
      });
    }
    this.view.setState(initialState);
    this._updateHistoryStatus();
  }

  setCursor(pos?: number) {
    this.view.dispatch({
      selection: {
        anchor: Math.max(0, Math.min(this.view.state.doc.length, pos ?? 0)),
      },
    });
  }

  componentDidMount() {
    this.editorRef.current.appendChild(this.view.dom);

    $(window).on('beforeunload', () => {
      if (!this.props.currentFile) {
        return;
      }

      this.cursor[this.props.currentFile.id] =
        this.view.state.selection.main.head;
      localStorage.setItem('codeMirror6Cursor', JSON.stringify(this.cursor));
      if (this.props.waiting) {
        return t('view:editor.unload_while_saving');
      }
    });
  }

  componentWillUnmount() {
    localStorage.setItem('codeMirror6Cursor', JSON.stringify(this.cursor));
    $(window).off('beforeunload');
    this.view.destroy();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      this.props.editorPositionInfo.originator !==
        nextProps.editorPositionInfo.originator ||
      this.props.editorPositionInfo.input !==
        nextProps.editorPositionInfo.input ||
      this.props.editorPositionInfo.line !==
        nextProps.editorPositionInfo.line ||
      this.props.editorPositionInfo.column !==
        nextProps.editorPositionInfo.column
    ) {
      const info = _.assign({}, nextProps.editorPositionInfo);
      setTimeout(() => this._applySynctexFromPdf(info), 100);
    }

    if (
      this.props.showSynctexEditorIndicator !==
      nextProps.showSynctexEditorIndicator
    ) {
      const cursor = this.view.state.selection.main.head;
      const start = this.view.state.doc.lineAt(cursor).from;
      const end = this.view.state.doc.lineAt(cursor).to;
      if (nextProps.showSynctexEditorIndicator) {
        this.view.dispatch({
          selection: {
            anchor: start,
            head: end,
          },
        });
        setTimeout(() => this.view.focus(), 0);
      } else {
        this.view.dispatch({
          selection: {
            anchor: cursor,
          },
        });
      }
    }

    // Build syntax tree if current file is not compile target
    if (
      nextProps.compileTargetFile &&
      (nextProps.compileTargetFile.id !== nextProps.currentFile?.id ||
        nextProps.compileTargetFile.id !== this.props.compileTargetFile?.id) // When setting current file to compile target
    ) {
      if (nextProps.compileTargetFile.content !== null) {
        ensureCompileTargetDependencies(nextProps.compileTargetFile.content);
        this.triedToLoadCompileTargetFile = false;
      } else if (!this.triedToLoadCompileTargetFile) {
        EditorActions.openFile(nextProps.compileTargetFile.id, true);
        this.triedToLoadCompileTargetFile = true;
      }
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (
      nextState.mode !== this.state.mode ||
      this.props.conflictFileDialog !== nextProps.conflictFileDialog ||
      this.props.conflictFiles !== nextProps.conflictFiles ||
      this.props.loading !== nextProps.loading ||
      !this.props.currentFile ||
      (this.props.currentFile && !nextProps.currentFile) ||
      (nextProps.currentFile &&
        nextProps.currentFile.id !== this.props.currentFile.id)
    ) {
      return true;
    }

    if (
      (this.props.project && this.props.project.editor_theme) !==
      (nextProps.project && nextProps.project.editor_theme)
    ) {
      return true;
    }

    if (
      (this.props.project && this.props.project.key_bindings) !==
      (nextProps.project && nextProps.project.key_bindings)
    ) {
      return true;
    }

    if (this.props.project.font_size !== nextProps.project.font_size) {
      return true;
    }

    if (
      (this.props.project && this.props.project.enable_spell_check) !==
      (nextProps.project && nextProps.project.enable_spell_check)
    ) {
      return true;
    }

    if (
      (this.props.project && this.props.project.enable_latex_dev_mode) !==
      (nextProps.project && nextProps.project.enable_latex_dev_mode)
    ) {
      return true;
    }

    if (
      this.state.undoable !== nextState.undoable ||
      this.state.redoable !== nextState.redoable
    ) {
      return true;
    }

    if (
      this.props.errors !== nextProps.errors &&
      (this.props.errors.length !== nextProps.errors.length ||
        JSON.stringify(this.props.errors) !== JSON.stringify(nextProps.errors))
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.project?.enable_spell_check !==
        prevProps.project?.enable_spell_check ||
      this.props.project?.font_size !== prevProps.project?.font_size ||
      this.props.project?.key_bindings !== prevProps.project?.key_bindings ||
      this.props.project?.editor_theme !== prevProps.project?.editor_theme
    ) {
      this.reconfigureEditorPreference();
    }

    if (this.props.currentFile) {
      if (this.props.currentFile.name.match(/\.bib$/)) {
        EditorActions.loadBibtexItems();
      }

      if (this.props.currentFile.id !== prevProps.currentFile?.id) {
        if (prevProps.currentFile) {
          this.cursor[prevProps.currentFile.id] =
            this.view.state.selection.main.head;
          this.savedStates[prevProps.currentFile.id] = this.view.state.toJSON({
            history: historyField,
          });
        }

        this.setDocument(
          this.props.currentFile.content,
          this.savedStates[this.props.currentFile.id],
        );

        this.setCursor(this.cursor[this.props.currentFile.id]);

        this.view.focus();
        this.view.dispatch({
          effects: [
            EditorView.scrollIntoView(this.view.state.selection.main.head),
          ],
        });
      }
    }

    if (this.props.errors !== prevProps.errors) {
      this._setErrors();
    }
  }

  _handleS3FileAll() {
    EditorActions.closeConflictFileDialog();
    EditorActions.resolveAllFiles(FileSource.S3).then(() =>
      EditorActions.loadFiles().catch(() => {}),
    );
  }

  _handleDropboxFileAll() {
    EditorActions.closeConflictFileDialog();
    const num = 0;
    const { conflictFiles } = this.props;
    if (conflictFiles[num][2] != null) {
      if (
        this.props.currentFile &&
        conflictFiles[num][0] === this.props.currentFile.id
      ) {
        this.cursor[this.props.currentFile.id] =
          this.view.state.selection.main.head;
        if (conflictFiles[num][4] && conflictFiles[num][4].length > 0) {
          // Discard the state because the file is edited outside.
          this.setDocument(conflictFiles[num][4], undefined);
        }
        this.setCursor(this.cursor[this.props.currentFile.id]);

        EditorActions.resetRetry();
      }
    }

    EditorActions.resolveAllFiles(FileSource.DROPBOX).then(() =>
      EditorActions.loadFiles().catch(() => {}),
    );
  }

  _handleRerunSpellCheck() {
    this.forceUpdate();
  }

  _mkToolBarButtonsHandler(k: string) {
    const functions: { [key: string]: () => void } = {
      undo: () => {
        undo(this.view);
      },
      redo: () => {
        redo(this.view);
      },
      find: () => {
        openSearchPanel(this.view);
      },
    };

    return () => {
      const f = functions[k];
      if (f) {
        f();
      }
    };
  }

  _updateHistoryStatus() {
    this.setState({
      undoable: undoDepth(this.view.state) > 0,
      redoable: redoDepth(this.view.state) > 0,
    });
  }

  _onScroll() {
    if (
      !this.props.project ||
      !this.props.project.scroll_sync ||
      this.state.synctexScrolling
    ) {
      return;
    }
    const { from, to } = this.view.viewport;
    const fromLine = this.view.state.doc.lineAt(from).number;
    const toLine = this.view.state.doc.lineAt(to).number;
    EditorActions.synctexFromEditor(
      Math.floor((fromLine + toLine) / 2),
      this.props.currentFile?.full_path,
    );
  }

  _setErrors() {
    const errors = this.props.errors ?? [];
    const currentFileErrors = errors
      .filter(
        (err) => err.filename === this.props.currentFile?.name && err.line,
      )
      .map((err) => ({
        from:
          err.line && err.line <= this.view.state.doc.lines
            ? this.view.state.doc.line(err.line).from
            : 0,
        to:
          err.line && err.line <= this.view.state.doc.lines
            ? this.view.state.doc.line(err.line).to
            : 0,
        message: err.error_log,
        severity: 'error' as const,
      }));

    this.view.dispatch(setDiagnostics(this.view.state, currentFileErrors));
    return currentFileErrors;
  }

  _applySynctexFromPdf(info: CL2Types.EditorPositionInfo) {
    this.locator.locate(this.view, info);
    this.setState({ synctexScrolling: true });
    setTimeout(() => this.setState({ synctexScrolling: false }), 1000);
  }

  render() {
    const flexStyle = _.extend(this.props.style, {
      display: 'flex',
      flexFlow: 'column nowrap',
    });
    const appStyle: CSSProperties = {
      flex: '1',
      overflow: 'auto',
      position: 'relative',
    };
    const buttons = [];
    {
      const buttonStyle = {
        height: '100%',
        width: '54px',
        borderRadius: 'unset',
      };
      const buttons_mdiIconClassName = {
        undo: 'undo',
        redo: 'redo',
        replace: 'find_replace',
        find: 'search',
        findNext: 'arrow_downward',
        findPrev: 'arrow_upward',
      };
      const isDisabled = (key: 'undo' | 'redo' | 'find'): boolean => {
        switch (key) {
          case 'find':
            return false;
          case 'undo':
            return !this.state.undoable;
          case 'redo':
            return !this.state.redoable;
        }
      };
      buttons.push(
        <PaneSplitterContext.Consumer key="leftButton">
          {(value) => value.leftButton}
        </PaneSplitterContext.Consumer>,
      );
      (this.props.project?.permission.edit
        ? [
            'undo' as const,
            'redo' as const,
            'sepDummy' as const,
            'find' as const,
          ]
        : ['find' as const]
      ).forEach((actionName: 'undo' | 'redo' | 'sepDummy' | 'find') => {
        let button = null;
        if (actionName === 'sepDummy') {
          button = (
            <span
              key={actionName}
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                height: '100%',
                width: '8px',
              }}
            />
          );
        } else {
          const k = actionName;
          const iconAttrs: { style: React.CSSProperties } = {
            style: { fontSize: '24px' },
          };
          if (/^find[A-Z]/.test(actionName)) {
            iconAttrs.style.position = 'relative';
            iconAttrs.style.left = '-1px';
          }
          button = (
            <IconButtonEx
              disabled={isDisabled(k)}
              key={actionName}
              onClick={this._mkToolBarButtonsHandler(k)}
              children={
                <Icon {...iconAttrs}>
                  {buttons_mdiIconClassName[actionName]}
                </Icon>
              }
              style={buttonStyle}
              disableRipple
            />
          );
          if (!button.props.disabled) {
            button = (
              <Tooltip
                key={actionName}
                title={
                  <span style={{ fontSize: '14px' }}>
                    {t(`view:editor.tool-tip.${actionName}`)}
                  </span>
                }
                placement="bottom-end"
              >
                {button}
              </Tooltip>
            );
          }
        }
        buttons.push(button);
      });
      buttons.push(
        <PaneSplitterContext.Consumer key="rightButton">
          {(value) => value.rightButton}
        </PaneSplitterContext.Consumer>,
      );
    }

    const app_editor_root = (
      <div id="app_editor_root" style={appStyle}>
        {this.props.loading ? <Loading /> : null}
        <div
          ref={this.editorRef}
          style={{ height: '100%', backgroundColor: '#fff' }}
        />
        <ConflictFileDialog
          ref="conflictFileDialog"
          project={this.props.project}
          conflictFileDialog={this.props.conflictFileDialog}
          conflictFiles={this.props.conflictFiles}
          open={
            this.props
              .conflictFileDialog /* && this.props.conflictFiles[0][3] && this.props.conflictFiles[0][6] */
          }
          title={t('view:editor.file_conflicted')}
          existS3
          existDropbox
          s3Handler={this._handleS3FileAll.bind(this)}
          dropboxHandler={this._handleDropboxFileAll.bind(this)}
        />
      </div>
    );

    return (
      <>
        <div style={this.props.currentFile ? flexStyle : { display: 'none' }}>
          <EditorToolbarDiv id="app_editor_toolbuttons">
            {buttons}
          </EditorToolbarDiv>
          {app_editor_root}
        </div>
        {this.props.projectLoading ||
        this.props.loading ||
        this.props.currentFile ? null : (
          <EmptyPlaceholder />
        )}
      </>
    );
  }
}
