import { EditorView, KeyBinding } from '@codemirror/view';
import { EditorSelection, StateCommand } from '@codemirror/state';
import { stexLanguage } from './stex';

const mathBrackets = [
  { open: '\\[', close: '\\]' },
  { open: '\\(', close: '\\)' },
  { open: '\\{', close: '\\}' },
  {
    open: '$',
    close: '$',
    ignorePrefix: '\\',
    handleBackspace: true,
  },
];

// Register the last char of open as trigger key, that means completion starts when you type that key
const startKeys = mathBrackets.map((pair) =>
  pair.open.charAt(pair.open.length - 1),
);

// Modified from autoCloseTags
// https://github.com/codemirror/lang-html/blob/6.4.6/src/html.ts#L163-L202
export const autoCloseMathBrackets = EditorView.inputHandler.of(
  (view, from, to, text, insertTransaction) => {
    if (
      view.composing ||
      view.state.readOnly ||
      from !== to ||
      !startKeys.includes(text) ||
      !stexLanguage.isActiveAt(view.state, from, -1)
    ) {
      return false;
    }

    const base = insertTransaction();
    const { state } = base;
    const closeTags = state.changeByRange((range) => {
      for (const brackets of mathBrackets) {
        const previous = state.doc.sliceString(
          range.from - brackets.open.length,
          range.to,
        );
        if (previous === brackets.open) {
          if (brackets.ignorePrefix) {
            const prefix = state.doc.sliceString(
              range.from - brackets.open.length - brackets.ignorePrefix.length,
              range.from - brackets.open.length,
            );
            if (prefix === brackets.ignorePrefix) {
              break;
            }
          }
          return {
            range,
            changes: {
              from: range.head,
              to: range.head,
              insert: brackets.close,
            },
          };
        }
      }
      return { range };
    });
    if (closeTags.changes.empty) return false;
    view.dispatch([
      base,
      state.update(closeTags, {
        userEvent: 'input.complete',
        scrollIntoView: true,
      }),
    ]);
    return true;
  },
);

// Modified from https://github.com/codemirror/autocomplete/blob/6.9.1/src/closebrackets.ts#L93-L110
// Command that implements deleting a pair of matching brackets when
// the cursor is between them.
export const deleteBracketPair: StateCommand = ({ state, dispatch }) => {
  if (state.readOnly) return false;
  let dont = null;
  const changes = state.changeByRange((range) => {
    if (range.empty) {
      for (const brackets of mathBrackets) {
        if (brackets.handleBackspace) {
          const before = state.doc.sliceString(
            range.head - brackets.open.length,
            range.head,
          );
          if (brackets.ignorePrefix) {
            const prefixBefore = state.doc.sliceString(
              range.head - brackets.open.length - brackets.ignorePrefix.length,
              range.head - brackets.open.length,
            );
            if (prefixBefore === brackets.ignorePrefix) {
              return { range: (dont = range) };
            }
          }
          const after = state.doc.sliceString(
            range.head,
            range.head + brackets.open.length,
          );
          if (brackets.open === before && brackets.close === after) {
            return {
              changes: {
                from: range.head - brackets.open.length,
                to: range.head + brackets.close.length,
              },
              range: EditorSelection.cursor(range.head - brackets.open.length),
            };
          }
        }
      }
    }
    return { range: (dont = range) };
  });
  if (!dont)
    dispatch(
      state.update(changes, {
        scrollIntoView: true,
        userEvent: 'delete.backward',
      }),
    );
  return !dont;
};

export const closeMathBracketsKeymap: readonly KeyBinding[] = [
  { key: 'Backspace', run: deleteBracketPair },
];
