import * as request from 'superagent';
import {
  Completion,
  CompletionContext,
  CompletionInfo,
  CompletionResult,
  snippet,
  startCompletion,
} from '@codemirror/autocomplete';
import { EditorView } from '@codemirror/view';
import { EditorState } from '@codemirror/state';
import { syntaxTree } from '@codemirror/language';
import { SyntaxNode } from '@lezer/common';
import * as CL2Types from '../CL2Types';
import { isEPSFile, isImageFile, isSVGFile, isTiffFile } from './CommonUtils';
import { APIRoot } from '../constants/CommonConstants';
import { stex } from '../../codemirror/language/stex';
import EditorWebAPIUtils from './EditorWebAPIUtils';

type SnippetCompletion =
  | string
  | ({
      template?: string;
    } & Completion);

declare module '@codemirror/autocomplete' {
  interface Completion {
    packages?: string[];
  }
}

let commandsCache: Completion[] = [];

const CloudLatex = (function () {
  //* * TeX Reference Card */
  const greekLetters: string[] =
    '\\alpha \\iota \\varrho \\beta \\kappa \\sigma \\gamma \\lambda \\varsigma \\delta \\mu \\tau \\epsilon \\nu \\upsilon \\varepsilon \\xi \\phi \\zeta \\o \\varphi \\eta \\pi \\chi \\theta \\varpi \\psi \\vartheta \\rho \\omega \\Gamma \\Xi \\Phi \\Delta \\Pi \\Psi \\Theta \\Sigma \\Omega \\Lambda \\Upsilon'.split(
      ' ',
    );

  const symbolsOfTypeOrd: string[] =
    '\\aleph \\prime \\forall \\hbar \\emptyset \\exists \\imath \\nabla \\neg \\lnot \\jmath \\surd \\flat \\ell \\top \\natural \\wp \\bot \\sharp \\Re \\clubsuit \\Im \\angle \\diamondsuit \\partial \\triangle \\heartsuit \\infty \\backslash \\spadesuit'.split(
      ' ',
    );

  const largeOperators: string[] =
    '\\sum \\bigcap \\bigodot \\prod \\bigcup \\bigotimes \\coprod \\bigsqcup \\bigoplus \\int \\bigvee \\biguplus \\oint \\bigwedge'.split(
      ' ',
    );

  const binaryOperations: string[] =
    '\\pm \\cap \\vee \\lor \\mp \\cup \\wedge \\land \\setminus \\uplus \\oplus \\cdot \\sqcap \\ominus \\times \\sqcup \\otimes \\ast \\triangleleft \\oslash \\star \\triangleright \\odot \\diamond \\wr \\dagger \\circ \\bigcirc \\ddagger \\bullet \\bigtriangleup \\amalg \\div \\bigtriangledown'.split(
      ' ',
    );

  const relations: string[] =
    '\\leq \\le \\geq \\ge \\neq \\equiv \\prec \\succ \\sim \\preceq \\succeq \\simeq \\ll \\gg \\asymp \\subset \\supset \\approx \\subseteq \\supseteq \\cong \\sqsubseteq \\sqsupseteq \\bowtie \\in \\notin \\ni \\owns \\vdash \\dashv \\models \\smile \\mid \\not \\doteq \\frown \\parallet \\parallel \\nparallel \\perp \\propto \\not \\ne'.split(
      ' ',
    );

  const arrows: string[] =
    '\\leftarrow \\gets \\longleftarrow \\Leftarrow \\Longleftarrow \\rightarrow \\to \\longrightarrow \\Rightarrow \\Longrightarrow \\leftrightarrow \\longleftrightarrow \\Leftrightarrow \\Longleftrightarrow \\mapsto \\longmapsto \\hookleftarrow \\hookrightarrow \\uparrow \\Uparrow \\downarrow \\Downarrow \\updownarrow \\Updownarrow \\nearrow \\searrow \\nwarrow \\swarrow'.split(
      ' ',
    );

  const delimiters: string[] =
    '\\lbrack \\lbrace \\langle \\rbrack \\rbrace \\rangle \\vert \\lfloor \\lceil \\Vert \\rfloor \\rceil \\left \\right'.split(
      ' ',
    );

  const accents: string[] =
    '\\hat \\widehat \\check \\tilde \\widetilde \\acute{?} \\grave \\dot \\ddot \\breve \\bar \\vec'.split(
      ' ',
    );

  const nonItalicFunctionNames: string[] =
    '\\arccos \\cos \\csc \\exp \\ker \\limsup \\min \\sinh \\arcsin \\cosh \\deg \\gcd \\lg \\ln \\Pr \\sup \\arctan \\cot \\det \\hom \\lim \\log \\sec \\tan \\arg \\coth \\dim \\inf \\liminf \\max \\sin \\tanh'.split(
      ' ',
    );

  const usefulParameters: string[] = '\\year \\month \\day \\jobname'.split(
    ' ',
  );

  const fillsLeadersEllipses: string[] =
    '\\dots \\ldots \\cdots \\vdots \\ddots \\hrulefill \\rightarrowfill \\leftarrowfill \\dotfill'.split(
      ' ',
    );

  const elementaryMathControlSequences: string[] =
    '\\overline{?} \\overrightarrow{?} \\underline{?} \\sqrt{?} \\frac{?}{} \\nonumber \\mathbf{?} \\mathit{?} \\mathrm{?} \\mathsf{?} \\displaystyle \\textstyle \\scriptstyle \\limits \\mathstrut'.split(
      ' ',
    );

  const specialCharacters: string[] =
    '\\textbackslash \\llap \\textbraceleft \\textbraceright \\textdollar \\textasciicircum \\textunderscore \\textasciitilde \\textless \\textgreater \\textbar'.split(
      ' ',
    );

  const verbInput: string[] = '\\verb|?| \\verb*|?|'.split(' ');

  const ams: string[] =
    '\\binom{?}{} \\cfrac{?}{} \\dfrac{?}{} \\text{?} \\boldsymbol'.split(' ');

  const siunitx: string[] =
    '\\ang{?} \\degreeCelsius \\qty{?}{} \\radian \\unit{?}'.split(' ');

  const physics: string[] =
    '\\qty(?) \\qty{?} \\qty[?] \\dd{?} \\dv{?} \\pdv{?} \\var{?} \\fdv{?} \\mqty{?} \\mqty[] \\dmat{?} \\dmat[?]{} \\bra{?} \\ket{?} \\ketbra{?} \\ev{?} \\mel{?}{}{}'.split(
      ' ',
    );

  /** LaTeX Cheet Sheet */
  const generalCommands: string[] =
    '\\documentclass[?]{} \\begin{?} \\end{?} \\includegraphics[?]{} \\usepackage{?} \\item \\item[?] \\LaTeX \\LaTeXe \\TeX \\caption{?} \\caption[]{?} \\footnote[]{?} \\lstinputlisting[?]{} \\footnote{?} \\footnotemark \\footnotetext \\input{?} \\include{?} \\includepdf{?} \\tableofcontents'.split(
      ' ',
    );

  const hyperref: string[] =
    '\\url{?} \\href{?} \\hypertarget{?}{} \\hyperlink{?}{}'.split(' ');

  const title: string[] =
    '\\author{?} \\title{?} \\subtitle{?} \\date{?} \\maketitle \\thanks{?} \\and \\institute{?}'.split(
      ' ',
    );

  const documentStructure: string[] =
    '\\part{?} \\chapter{?} \\section{?} \\subsection{?} \\subsubsection{?} \\paragraph{?} \\subparagraph{?} \\part*{?} \\chapter*{?} \\section*{?} \\subsection*{?} \\subsubsection*{?} \\paragraph*{?} \\subparagraph*{?}'.split(
      ' ',
    );

  const textProperties: string[] =
    '\\textrm{?} \\textsf{?} \\texttt{?} \\textmd{?} \\textbf{?} \\textup{?} \\textit{?} \\textsl{?} \\textsc{?} \\textgt{?} \\emph{?} \\textnormal{?} \\textcolor{?} \\textmc{?}'.split(
      ' ',
    );

  const fontSize: string[] =
    '\\tiny \\scriptsize \\footnotesize \\small \\normalsize \\large \\Large \\LARGE \\huge \\Huge \\fontsize{?}{} \\selectfont'.split(
      ' ',
    );

  const lineBreak: string[] =
    '\\kill \\pagebreak \\noindent \\par \\newline'.split(' ');

  const miscellaneous: string[] =
    '\\today \\hspace{?} \\vspace{?} \\rule{?}{} \\setcounter{?} \\marginpar{?} \\reflectbox \\rotatebox \\scalebox \\raisebox{?}[][]{}'.split(
      ' ',
    );

  const tabular: string[] =
    '\\hline \\cline{?} \\multicolumn{?}{}{} \\multirow{?}{}{}'.split(' ');

  const booktabs: string[] =
    '\\toprule \\midrule \\bottomrule \\cmidrule'.split(' ');

  const citation: string[] =
    '\\cite{?} \\citeA{?} \\citeN{?} \\shortcite{?} \\bibliographystyle{?} \\bibliography{?} \\shortciteA{?} \\shortciteN{?} \\citeyear{?} \\bibitem{?}'.split(
      ' ',
    );

  const reference: string[] =
    '\\ref{?} \\eqref{?} \\label{?} \\pageref{?}'.split(' ');

  const pageBreak: string[] = '\\linebreak \\newpage \\clearpage'.split(' ');

  const arbitraryWords: string[] =
    '\\newcommand{?}{} \\def \\renewcommand{?}{} \\setlength{?}{}'.split(' ');

  const framedText: string[] =
    '\\fbox{?} \\framebox{?} \\mbox{?} \\doblebox{?} \\hbox{?} \\vbox{?} \\ovalbox{?} \\Ovalbox{?} \\shadowbox{?}'.split(
      ' ',
    );

  const textAlignment: string[] =
    '\\raggedright \\raggedleft \\leftline{?} \\centerline{?} \\rightline{?} \\centering'.split(
      ' ',
    );

  const index: string[] = '\\index{?} \\makeindex \\printindex'.split(' ');

  const parenthesesControl: string[] =
    '\\left \\right \\bigl \\bigr \\Bigl \\Bigr \\biggl \\biggr \\Biggl \\Biggr'.split(
      ' ',
    );

  const colortbl: string[] = '\\columncolor \\rowcolor \\cellcolor'.split(' ');

  const pageStyle: string[] =
    '\\pagestyle{?} \\thispagestyle{?} \\markboth{?}{} \\pagenumbering{?}'.split(
      ' ',
    );

  const horizontalSpace: string[] =
    '\\enspace \\quad \\qquad \\hfill \\smallskip \\medskip \\bigskip \\vfill \\phantom{?} \\,'.split(
      ' ',
    );

  const tSing: string[] =
    '\\dag \\ddag \\copyright \\pounds \\texttrademark \\textregistered \\textordfeminine \\textordmasculine \\textbardbl \\textbullet'.split(
      ' ',
    );

  const listings: string[] =
    '\\lstlisting[]{?} \\lstdefinelanguage{?}{} \\lstset{?} \\lstdefinestyle{}{?}'.split(
      ' ',
    );

  const layout: string[] =
    '\\linewidth \\textheight \\textwidth \\twocolumn \\onecolumn'.split(' ');

  const beamer: string[] =
    '\\usetheme{?} \\usecolortheme{?} \\usefonttheme{?} \\useinnertheme{?} \\useoutertheme{?} \\setbeamertemplate{?}{} \\setbeamercolor{?}{} \\setbeamerfont{}{} \\setbeamercovered{?} \\insertframetitle \\insertframesubtitle \\frametitle{?} \\framesubtitle{?} \\logo{?} \\pause \\only<?>{} \\uncover<?>{} \\invisible<?>{} \\alt<?>{} \\temporal<?>{}{}{} \\beamergotobutton{?}'.split(
      ' ',
    );

  const AutoCompleteCommands = []
    .concat(
      greekLetters,
      symbolsOfTypeOrd,
      largeOperators,
      binaryOperations,
      relations,
      arrows,
      delimiters,
      accents,
      nonItalicFunctionNames,
      usefulParameters,
      fillsLeadersEllipses,
      elementaryMathControlSequences,
      generalCommands,
      title,
      documentStructure,
      textProperties,
      fontSize,
      lineBreak,
      miscellaneous,
      tabular,
      booktabs,
      citation,
      reference,
      arbitraryWords,
      framedText,
      textAlignment,
      index,
      specialCharacters,
      ams,
      siunitx,
      physics,
      parenthesesControl,
      hyperref,
      colortbl,
      verbInput,
      pageBreak,
      pageStyle,
      horizontalSpace,
      tSing,
      listings,
      layout,
      beamer,
    )
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  const AdditionalOptions: {
    [tag: string]: { [bracket: string]: SnippetCompletion[] };
  } = {
    '\\documentclass': {
      '[': '10pt 11pt 12pt letterpaper a4paper a4j twocolumn twoside landscape draft'.split(
        ' ',
      ),
      '{': 'article report book jsarticle jsreport jsbook jlreq beamer'.split(
        ' ',
      ),
    },
    '\\usepackage': {
      '{': 'abstract accents amsmath amssymb amsthm appendix array arydshln ascmac autobreak beamerposter bm booktabs braket breqn cancel caption colortbl comment dcolumn diagbox diffcoeff empheq endfloat endnotes enumitem esvect fancyhdr fancybox float ftnright geometry graphicx hyperref lipsum listings lmodern longtable luatexja marginnote mathrsfs mathtools mhchem mleftright mparhack multirow newpxtext newtxtext nicematrix ntheorem pdfpages physics sidenotes slashed subcaption tablefootnote tcolorbox tensor tikz tocloft unicode-math upgreek wrapfig xcolor'.split(
        ' ',
      ),
    },
    '\\includegraphics': {
      '[': 'keepaspectratio width= height= scale='.split(' '),
      '{': [],
    },
    '\\lstinputlisting': {
      '[': 'caption= label= style='.split(' '),
      '{': [],
    },
    '\\begin': {
      '{1': [
        ...'align align* array block bmatrix cases center comment document equation eqnarray flushleft flushright gather gather* itembox lstlisting minipage multiline picture pmatrix quotation quote split thebibliography verbatim verbatim* verse vmatrix'.split(
          ' ',
        ),
        {
          label: 'description',
          template: '\n\t\\item #{}',
        },
        {
          label: 'enumerate',
          template: '\n\t\\item #{}',
        },
        {
          label: 'figure',
          template:
            '\n\t\\centering\n\t\\includegraphics[#{}]{#{}}\n\t\\caption{#{Caption}}\n\t\\label{#{fig:my_label}}',
        },
        {
          label: 'frame',
          template: '{#{Frame title}}\n\t#{}',
        },
        {
          label: 'itemize',
          template: '\n\t\\item #{}',
        },
        {
          label: 'table',
          template:
            '[#{}]\n\t\\centering\n\t\\caption{#{Caption}}\n\t\\label{#{tab:my_label}}\n\t\\begin{tabular}{#{c|c}}\n\t\t#{} &  \\\\\n\t\t & \n\t\\end{tabular}',
        },
        {
          label: 'tabular',
          template: '{#{c|c}}\n\t#{} & \\\\\n\t & ',
        },
        {
          label: 'tabularx',
          template: '{#{}}{#{c|c}}\n\t#{} & \\\\\n\t & ',
        },
      ].sort((a, b) => {
        if (typeof a !== 'string') {
          a = a.label;
        }
        if (typeof b !== 'string') {
          b = b.label;
        }
        return a.localeCompare(b);
      }),
    },
    '\\ref': {
      '{': [],
    },
    '\\eqref': {
      '{': [],
    },
    '\\cite': {
      '{': [],
    },
    '\\input': {
      '{': [],
    },
    '\\include': {
      '{': [],
    },
    '\\lstset': {
      '{': 'basicstyle= breakindent= breaklines= caption= captionpos= commentstyle= frame= framesep=  keywordstyle= language= numbers= numbersep= numberstyle= showspaces= showtabs=  stepnumber= tab='.split(
        ' ',
      ),
    },
  };

  const bracketOpen = /[({\[]/;
  const bracketClose = /[})\]]/;

  const BracketRegExp = {
    closeOpenClose: RegExp(
      bracketClose.source + bracketOpen.source + bracketClose.source,
    ),
  };

  return { AutoCompleteCommands, AdditionalOptions, BracketRegExp };
})();

function getOptionCommands(tag: string, delimiter: string, num: number) {
  const delimiterKey = num > 0 ? delimiter + num.toString() : delimiter;
  return (
    CloudLatex.AdditionalOptions[tag]?.[delimiterKey] ??
    CloudLatex.AdditionalOptions[tag]?.[delimiter]
  );
}

function toFileNameCompletions(Files: CL2Types.ProjectFile[]): Completion[] {
  return Files.map((file) => {
    let hasloadedImage = false;
    const detailImg = document.createElement('img');
    detailImg.classList.add('detail');
    return {
      label: file.full_path,
      info(completion: Completion) {
        return new Promise((resolve) => {
          if (
            isImageFile(file.mimetype) &&
            !isEPSFile(file.mimetype) &&
            !isSVGFile(file.mimetype) &&
            !isTiffFile(file.mimetype)
          ) {
            if (!hasloadedImage) {
              hasloadedImage = true;
              detailImg.src = file.thumbnail_url;
              detailImg.onload = () => {
                resolve(detailImg);
              };
            } else {
              resolve(detailImg);
            }
          }
        });
      },
    };
  });
}

let compileTargetDependencies = [];

function getNearestTag(
  state: EditorState,
  pos: number,
): { tag: string; delimiter: string; bracketNo: number; blank?: boolean } {
  const token = syntaxTree(state).resolveInner(pos, -1);
  let node: SyntaxNode;
  for (node = token; node; node = node.parent) {
    // Not considered as tag arguments if brackets are nested
    if (node.name === 'Option') {
      if (node.parent?.name === 'Tag') {
        node = node.parent;
      }
      break;
    }
  }
  if (!node) {
    return {
      tag: '',
      delimiter: '',
      bracketNo: 0,
    };
  }

  const tag = node.firstChild;

  const options = node.getChildren('Option');
  const bracketNo =
    options.findIndex((option) => {
      if (option.lastChild.name === '⚠') {
        // incomplete option
        return option.from <= pos && option.to >= pos;
      }
      return option.from < pos && option.to > pos; // Definitely inside bracket
    }) + 1;

  const option = options[bracketNo - 1];
  const delimiter = option?.firstChild;
  const blank =
    delimiter?.nextSibling.name === '⚠' ||
    delimiter?.nextSibling.name === '}' ||
    delimiter?.nextSibling.name === ']';

  return {
    tag: tag ? state.sliceDoc(tag.from, tag.to) : '',
    delimiter: delimiter?.name ?? '',
    bracketNo,
    blank,
  };
}

function shouldStartOptionCompletion(
  view: EditorView,
  pointer: boolean,
): boolean {
  // Don't show hints when selected
  if (view.state.selection.main.from !== view.state.selection.main.to) {
    return false;
  }

  const nearestTag = getNearestTag(view.state, view.state.selection.main.head);
  const shouldShowFilenameCompletions =
    pointer &&
    ['\\includegraphics', '\\input', '\\include', '\\lstinputlisting'].includes(
      nearestTag.tag,
    ) &&
    nearestTag.delimiter === '{';

  return (
    (shouldShowFilenameCompletions || nearestTag.blank) &&
    !!getOptionCommands(
      nearestTag.tag,
      nearestTag.delimiter,
      nearestTag.bracketNo,
    )
  );
}

export function triggerOptionCompletion(
  view: EditorView,
  pointer: boolean = true,
) {
  if (shouldStartOptionCompletion(view, pointer)) {
    startCompletion(view);
  }
}

function getTargetToken(context: CompletionContext): {
  string: string;
  type: string;
  from: number;
} {
  const currentCursor = context.pos;
  const node = syntaxTree(context.state).resolveInner(currentCursor, -1);

  const string = context.state.sliceDoc(node.from, node.to);

  if (
    node.name === 'Document' ||
    node.name === 'Content' ||
    node.name === 'Atom' || // Not tokenized
    node.name === '(' ||
    node.name === ')' ||
    node.firstChild // It's a space before cursor
  ) {
    const stringBefore = context.matchBefore(
      /[\w\xc0-\u1fff\u2060-\uffff\.\-\/\(\)]+[=*]?$/,
    ) || {
      text: '',
      from: currentCursor,
    };
    if (stringBefore.text.match(/[=*]$/)) {
      return {
        string: stringBefore.text,
        type: null,
        from: stringBefore.from,
      };
    }
    const line = context.state.doc.lineAt(currentCursor);
    const end = Math.min(line.to, currentCursor + 250);
    const stringAfter = context.state.sliceDoc(currentCursor, end);
    const found = stringAfter.match(
      /^[\w\xc0-\u1fff\u2060-\uffff\.\-\/\(\)]*[=*]?/,
    );
    const string = !found ? stringBefore.text : stringBefore.text + found[0];
    return {
      string,
      type: null,
      from: stringBefore.from,
    };
  }
  if ('()[]{}'.includes(node.name)) {
    // Not inserted anything yet
    return {
      string: '',
      type: null,
      from: node.to,
    };
  }
  if (string.match(/^\\[}\]]$/)) {
    // If you are starting a tag with "\" inside {} or []
    return {
      string: '\\',
      type: node.name,
      from: node.from,
    };
  }
  return {
    string,
    type: node.name,
    from: node.from,
  };
}

function isSnippetCompletion(
  completion: SnippetCompletion | Completion,
): completion is SnippetCompletion {
  return typeof (completion as { template: string }).template !== 'undefined';
}

function toCompletions(
  commandList: (SnippetCompletion | Completion)[],
  token,
  nearestTag,
): Completion[] {
  const isBegin =
    nearestTag.tag === '\\begin' &&
    nearestTag.delimiter === '{' &&
    nearestTag.bracketNo === 1;
  if (
    isBegin &&
    token.string.match(/^[A-Za-z]+$/) &&
    commandList.findIndex((command) =>
      typeof command === 'string'
        ? command === token.string
        : command.label === token.string,
    ) < 0
  ) {
    commandList = [{ label: token.string, boost: -200 }, ...commandList]; // put the token itself at the bottom
  }

  return commandList.map((command) => {
    if (isBegin) {
      let label: string;
      let templateContent: string = '\n\t#{}';
      if (typeof command === 'string') {
        label = command.replace('?', '');
      } else {
        label = command.label;
        if (isSnippetCompletion(command)) {
          templateContent = command.template ?? templateContent;
        }
      }
      return {
        label,
        apply: (
          view: EditorView,
          completion: Completion,
          from: number,
          to: number,
        ) => {
          let node = syntaxTree(view.state).resolveInner(from, -1);
          let template = `${label}}${templateContent}\n\\end{${label}}`;
          while (node.parent && node.name !== 'Option') node = node.parent;
          const child = node.enter(from, 1) ?? node.childAfter(from);
          if (
            !child ||
            child.name === '}' ||
            (child.from === from && child.to === to)
          ) {
            // \begin{|}, \begin{ho|ge}
            to = node.to;
          } else {
            // \begin{|hoge}
            template += '{';
          }
          snippet(template)(view, completion, from, to);
          triggerOptionCompletion(view);
        },
        boost: typeof command !== 'string' ? command.boost : undefined,
      };
    }
    if (typeof command === 'string' || !command.apply) {
      const commandString =
        typeof command === 'string' ? command : command.label;
      const label = commandString.replace('?', '');
      const packages = typeof command === 'string' ? null : command.packages;
      const info:
        | false
        | string
        | ((c: Completion) => CompletionInfo | Promise<CompletionInfo>) =
        typeof command !== 'string' &&
        (command.packages?.length > 1
          ? (c) => {
              const div = document.createElement('div');
              div.append(
                ...command.packages.map((packageName) => {
                  const d = document.createElement('div');
                  d.innerText = packageName;
                  d.classList.add('cm-completionPackageName');
                  return d;
                }),
              );
              return div;
            }
          : command.info);

      const isPlain = !commandString.match(/\?|{}|\[]/);
      if (isPlain) {
        return { label, packages, info };
      }

      const bracketPattern = /(\{})|(\[])/g;

      let match;
      let newCommand = '';
      let index = 0;
      for (
        let i = commandString.indexOf('?') >= 0 ? 2 : 1;
        (match = bracketPattern.exec(commandString));
        i += 1
      ) {
        const bracket = match[0];
        const size = match[0].length / 2;
        const newBracket = `${bracket.slice(0, size)}#{}${bracket.slice(-size)}`;
        newCommand += commandString.slice(index, match.index) + newBracket;
        index = bracketPattern.lastIndex;
      }
      newCommand += commandString.slice(index);
      newCommand = newCommand.replace('?', '#{1}');

      return {
        label,
        apply: (
          view: EditorView,
          completion: Completion,
          from: number,
          to: number,
        ) => {
          snippet(newCommand)(view, completion, from, to);
          triggerOptionCompletion(view);
        },
        packages,
        info,
      };
    }
    return command;
  });
}

function getCommandList(): Completion[] {
  return commandsCache;
}

export function stexCompletions(
  context: CompletionContext,
  graphicsFiles: CL2Types.ProjectFile[],
  textFiles: CL2Types.ProjectFile[],
  texFiles: CL2Types.ProjectFile[],
  bibtexItems: CL2Types.BibtexItems,
): CompletionResult {
  const currentCursor = context.pos;
  const token = getTargetToken(context);
  const nearestTag = getNearestTag(context.state, currentCursor);

  const optionalSuggestCommands = getOptionCommands(
    nearestTag.tag,
    nearestTag.delimiter,
    nearestTag.bracketNo,
  );

  if (
    !context.explicit &&
    (optionalSuggestCommands ? !token.string : !context.matchBefore(/\\\w*/))
  ) {
    return null;
  }

  let commandList: (SnippetCompletion | Completion)[];
  if (optionalSuggestCommands) {
    if (token.string.match(/=$/)) {
      // We don't have proper suggestions after =
      commandList = null;
    } else {
      commandList = optionalSuggestCommands;
    }
  } else if (Object.keys(commandsCache).length) {
    commandList = getCommandList();
  } else {
    commandList = CloudLatex.AutoCompleteCommands;
  }

  if (['\\ref', '\\eqref'].includes(nearestTag.tag)) {
    const labels = context.state.doc.toString().match(/\\label\{([^}]+)+\}/g);
    if (labels) {
      commandList = [].concat(
        commandList,
        labels.map((label) => label.replace(/\\label\{|\}/g, '')),
      );
    }
  } else if (nearestTag.tag === '\\cite') {
    commandList = [].concat(commandList, bibtexItems);
    const bibitems = context.state.doc
      .toString()
      .match(/\\bibitem\{([^}]+)+\}/g);
    if (bibitems) {
      commandList = [].concat(
        commandList,
        bibitems.map((label) => label.replace(/\\bibitem\{|\}/g, '')),
      );
    }
  } else if (
    nearestTag.tag === '\\includegraphics' &&
    nearestTag.delimiter === '{'
  ) {
    commandList = [].concat(commandList, toFileNameCompletions(graphicsFiles));
  } else if (
    ['\\input', '\\include'].includes(nearestTag.tag) &&
    nearestTag.delimiter === '{'
  ) {
    commandList = [].concat(commandList, toFileNameCompletions(texFiles));
  } else if (
    nearestTag.tag === '\\lstinputlisting' &&
    nearestTag.delimiter === '{'
  ) {
    commandList = [].concat(commandList, toFileNameCompletions(textFiles));
  }

  if (!commandList) {
    return;
  }

  return {
    from: token.from,
    to: token.from + token.string.length,
    options: toCompletions(commandList, token, nearestTag),
    validFor(text, from, to, state) {
      return nearestTag.tag !== '\\begin';
    },
    update(current, from, to, context) {
      if (nearestTag.tag === '\\begin') {
        const token = getTargetToken(context);
        return {
          ...current,
          from,
          to,
          options: toCompletions(commandList, token, nearestTag),
        };
      }
    },
  };
}

function getFirstArgumentValue(state: EditorState, node: SyntaxNode): string {
  if (node.name !== 'Tag') {
    return '';
  }
  for (const option of node.getChildren('Option')) {
    if (option.firstChild.name === '{') {
      const val = option.firstChild.nextSibling;
      if (!val) {
        return '';
      }
      let lastNode = val;
      while (lastNode.nextSibling && lastNode.nextSibling.name !== '}')
        lastNode = lastNode.nextSibling;
      return state.sliceDoc(val.from, lastNode.to);
    }
  }
  return '';
}

let requestAutocompleteId;
function requestAutocomplete(dependencies) {
  if (
    JSON.stringify(compileTargetDependencies) ===
    JSON.stringify(Array.from(new Set(dependencies)))
  ) {
    return;
  }
  clearTimeout(requestAutocompleteId);
  requestAutocompleteId = setTimeout(async () => {
    compileTargetDependencies = Array.from(new Set(dependencies));
    let commands = [];
    try {
      const response = await EditorWebAPIUtils.autocomplete(
        compileTargetDependencies,
      );
      commands = response?.body || [];
      CloudLatex.AutoCompleteCommands.forEach((a) => {
        const c = commands.find(
          (b) =>
            a.replace(/[\[\]\{}\?|]/g, '') ===
            b.label.replace(/[\[\]\{}]/g, ''),
        );
        if (!c) {
          commands.push({ label: a });
        } else if (c.label.length < a.length) {
          c.label = a;
        }
      });
      commandsCache = commands;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, 1000);
}

let compileTargetSyntaxTreeState = EditorState.create({
  extensions: [stex()],
});
export async function ensureCompileTargetDependencies(
  file?: string | EditorState,
) {
  if (!file) return;
  let state;
  if (typeof file === 'string') {
    if (compileTargetSyntaxTreeState.doc.toString() === file) {
      return;
    }
    compileTargetSyntaxTreeState = compileTargetSyntaxTreeState.update({
      changes: {
        from: 0,
        to: compileTargetSyntaxTreeState.doc.length,
        insert: file,
      },
    }).state;
    state = compileTargetSyntaxTreeState;
  } else {
    state = file;
  }
  const dependencies = [];
  const tree = syntaxTree(state);
  let node = tree.topNode.firstChild;
  while (node) {
    if (node.name === 'Tag') {
      const tagName = node.getChild('TagName');
      if (state.sliceDoc(tagName.from, tagName.to) === '\\usepackage') {
        dependencies.push(
          ...getFirstArgumentValue(state, node)
            .split(',')
            .map((s) => s.trim()),
        );
      } else if (
        state.sliceDoc(tagName.from, tagName.to) === '\\documentclass'
      ) {
        dependencies.push(
          ...getFirstArgumentValue(state, node)
            .split(',')
            .map((s) => s.trim()),
        );
      }
    }
    if (node.name === 'Block') {
      break;
    }
    node = node.nextSibling;
  }

  requestAutocomplete(dependencies);
}
