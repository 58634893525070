import { lazy } from 'react';
import { PdfjsBoundary } from '../PdfjsBoundary';
import type { Props } from './PdfViewer';

const PdfViewerComponent = lazy(() => import('./PdfViewer'));

export function PdfViewer(props: Omit<Props, 'paneSplitterContextValue'>) {
  return (
    <PdfjsBoundary>
      <PdfViewerComponent {...props} />
    </PdfjsBoundary>
  );
}
