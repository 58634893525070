import * as React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { ComponentType } from 'react';
import * as Colors from '@material-ui/core/colors';
import { LabComponentNameToClassKey } from '@material-ui/lab/themeAugmentation';

export const rootTheme = createTheme({
  palette: {
    text: {
      icon: Colors.grey['600'],
      iconButton: '#37474f',
      toolbar: Colors.blueGrey['700'],
      header: Colors.grey['700'],
      headerPrimary: Colors.lightBlue['700'],
      error: Colors.red['900'],
      warning: Colors.orange['900'],
    },
    blue: Colors.blue['700'],
    red: Colors.red['500'],
    background: {
      pane: Colors.blueGrey['50'],
      paneDivider: 'lightgray',
      paneDividerHover: '#add8e6',
      preview: Colors.grey['400'],
      toolbar: Colors.blueGrey['100'],
      primary: Colors.grey['50'],
      error: Colors.red['100'],
      warning: Colors.orange['100'],
    },
  },
  overrides: {
    MuiTypography: {
      body1: {
        fontSize: 14,
      },
    },
    MuiAlert: {
      root: {
        fontSize: 14,
      },
    },
  },
});

// Put this ThemeProvider to app root to enable proper theming.
// ThemeProviderを複数使って各コンポーネントのthemeを作るとき、rootに共通のThemeProviderがないと
// 各ThemeProviderが独立した名前空間を作りclassNameが競合してしまう
// https://github.com/cloudlatex-team/cloudlatex2/pull/1763#issuecomment-553798916
// ここで少し言及があるが、各コンポーネントに入れるだけでは競合したままなのでrootのThemeProviderを作る
export function withRootTheme<Props>(Component: ComponentType<Props>) {
  return function withRootThemeComponent(props: Props) {
    return (
      <ThemeProvider theme={rootTheme}>
        <Component {...props} />
      </ThemeProvider>
    );
  };
}
