import * as React from 'react';
import { createTheme, darken, ThemeProvider } from '@material-ui/core/styles';
import { ComponentType, FunctionComponent, ReactNode } from 'react';
import * as Colors from '@material-ui/core/colors';
import { CssBaseline } from '@material-ui/core';
import { deepmerge } from '@material-ui/utils';
import { themesList } from '../../codemirror/theme';

declare module '@material-ui/core/styles/createPalette' {
  interface TypeBackground {
    pane?: string;
    paneDivider?: string;
    paneDividerHover?: string;
    preview?: string;
    toolbar?: string;
    primary?: string;
    error?: string;
    warning?: string;
  }
  interface TypeText {
    icon?: string;
    iconButton?: string;
    toolbar?: string;
    header?: string;
    headerPrimary?: string;
    error?: string;
    warning?: string;
  }
  interface PaletteOptions {
    blue?: string;
    red?: string;
  }
}

const lightTheme = (outerTheme) =>
  deepmerge(
    outerTheme,
    createTheme({
      palette: {
        primary: { main: Colors.lightBlue['600'] },
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            body: {
              fontSize: 14,
            },
          },
        },
        MuiTooltip: {
          tooltip: {
            fontSize: '14px',
            lineHeight: 'normal',
          },
        },
        MuiButton: {
          root: {
            fontSize: 14,
          },
          sizeSmall: {
            fontSize: 12,
          },
        },
        MuiMenuItem: {
          root: {
            fontSize: 14,
          },
        },
      },
    }),
  );
const darkTheme = (outerTheme) =>
  deepmerge(
    deepmerge(outerTheme, lightTheme(outerTheme)),
    createTheme({
      palette: {
        type: 'dark',
        text: {
          primary: Colors.grey['50'],
          icon: Colors.grey['300'],
          iconButton: Colors.grey['300'],
          toolbar: Colors.blueGrey['100'],
          header: Colors.grey['300'],
          headerPrimary: Colors.lightBlue['300'],
          error: Colors.red['300'],
          warning: Colors.orange['300'],
        },
        primary: { main: Colors.cyan['300'] },
        blue: Colors.blue['300'],
        red: Colors.red['300'],
        background: {
          paper: Colors.grey['900'],
          pane: Colors.grey['900'],
          paneDivider: Colors.grey['700'],
          paneDividerHover: Colors.blueGrey['700'],
          preview: Colors.grey['600'],
          toolbar: Colors.blueGrey['800'],
          primary: Colors.common.black,
          error: darken(Colors.red['100'], 0.7),
          warning: darken(Colors.orange['100'], 0.7),
        },
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            body: {
              fontSize: 14,
            },
            a: {
              color: Colors.cyan['400'],
              '&:hover': {
                color: Colors.cyan['200'],
              },
              '&:focus': {
                color: Colors.cyan['100'],
              },
            },
          },
        },
        MuiCircularProgress: {
          colorPrimary: {
            color: Colors.indigo['A200'],
          },
        },
      },
    }),
  );

export function withEditorTheme<
  Props extends {
    project?: { editor_theme?: string };
  },
>(Component: ComponentType<Props> | FunctionComponent) {
  return function withEditorThemeComponent(props: Props) {
    const isDark =
      props.project &&
      themesList.filter((t) => t.name === props.project.editor_theme)[0]?.dark;
    return (
      <ThemeProvider theme={isDark ? darkTheme : lightTheme}>
        <CssBaseline />
        <Component {...props} />
      </ThemeProvider>
    );
  };
}
