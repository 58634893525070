import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from 'react-redux';
import {
  configureStore,
  createListenerMiddleware,
  isImmutableDefault,
} from '@reduxjs/toolkit';
import editorReducer, { State as EditorState } from '../reducers/editor';
import paneSplitterReducer, {
  State as PaneSplitterState,
} from '../features/paneSplitter/paneSplitterSlice';
import { loadSynctex } from '../actions/EditorActions';

// https://github.com/BBB/dropzone-redux-form-example/issues/5#issuecomment-1083565361
const isImmutable = (value) =>
  isImmutableDefault(value) || value instanceof File;
export const useSelector: TypedUseSelectorHook<{
  editor: EditorState;
  paneSplitter: PaneSplitterState;
}> = useReduxSelector;

const listenerMiddleware = createListenerMiddleware<typeof editorStore>();

const editorStore = configureStore({
  reducer: {
    editor: editorReducer,
    paneSplitter: paneSplitterReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: {
        isImmutable,
      },
    }).prepend(listenerMiddleware.middleware),
});

// load synctex after synctex uri is stored to share it with popped out windows
listenerMiddleware.startListening({
  predicate(action, currentState, previousState) {
    return (
      currentState.editor.result?.synctex_uri !==
      previousState.editor.result?.synctex_uri
    );
  },
  async effect(action, listenerApi) {
    const state = listenerApi.getState();
    loadSynctex(state.editor.result.synctex_uri);
  },
});
export { editorStore };
