import { AnyAction, createSlice } from '@reduxjs/toolkit';
import {
  autoWidth,
  expandOrShrinkToFitScreen,
  findAutoWidthPane,
  setWidthAndAdjustRight,
} from './widthArray';
import EditorConstants from '../../constants/EditorConstants';

const initialState = {
  widthArray: [], // either number for actual width or 'init' for initialize again to fit screen
  movingWidthArray: [],
  popoutPanes: [], // URLs of popped out windows
};

const paneSplitterSlice = createSlice({
  name: 'paneSplitter',
  initialState,
  reducers: {
    fitContent(state, action) {
      const newState = { ...state };
      const storedWidth =
        action.payload.storedWidth?.length ===
        action.payload.initialWidth.length
          ? action.payload.storedWidth?.slice(
              0,
              action.payload.initialWidth.length,
            )
          : undefined;
      let newArray = state.widthArray.length
        ? state.widthArray.map((width, i) =>
            width === 'init' ? action.payload.initialWidth[i] : width,
          )
        : storedWidth || action.payload.initialWidth;
      newArray = autoWidth(newArray, action.payload.screenWidth);
      newArray = expandOrShrinkToFitScreen(
        newArray,
        action.payload.screenWidth,
        findAutoWidthPane(action.payload.initialWidth),
      );
      newState.widthArray = newArray;
      return newState;
    },
    mouseMove(state, action) {
      const gap = action.payload.x - action.payload.startX;
      if (!state.widthArray.length) {
        return state;
      }

      const width = Math.min(
        Math.max(0, state.widthArray[action.payload.index] + gap),
        state.widthArray[action.payload.index] +
          state.widthArray[action.payload.index + 1],
      );

      return {
        ...state,
        movingWidthArray: setWidthAndAdjustRight(
          state.widthArray,
          action.payload.index,
          width,
        ),
      };
    },
    mouseUp(state) {
      return {
        ...state,
        movingWidthArray: [],
        widthArray: state.movingWidthArray,
      };
    },
    setWidthAndAdjustRight(state, action) {
      return {
        ...state,
        widthArray: setWidthAndAdjustRight(
          state.widthArray,
          action.payload.index,
          action.payload.width,
        ),
      };
    },
    setWidthAndAdjustLeft(state, action) {
      const width =
        state.widthArray[action.payload.index - 1] +
        state.widthArray[action.payload.index] -
        action.payload.width;
      return {
        ...state,
        widthArray: setWidthAndAdjustRight(
          state.widthArray,
          action.payload.index - 1,
          width,
        ),
      };
    },
    setInitialWidthAndAdjustLeft(state, action) {
      return {
        ...state,
        widthArray: [
          ...state.widthArray.slice(0, action.payload.index - 1),
          'init',
          'init',
          ...state.widthArray.slice(action.payload.index + 1),
        ],
      };
    },
    popoutPane(state, action) {
      if (action.payload.index === 0) {
        // 左側にポップインボタンを設置することにするので、左端をポップアウトすることは考えない
        throw new Error('not implemented');
      }

      window.open(
        action.payload.url,
        '',
        `width=600,height=600,left=${window.screenLeft + (window.innerWidth * 2) / 3},top=${window.screenTop}`,
      );

      return {
        ...state,
        widthArray: [
          ...state.widthArray.slice(0, action.payload.index - 1),
          'init',
          0,
          ...state.widthArray.slice(action.payload.index + 1),
        ],
        popoutPanes: [...state.popoutPanes, action.payload.url],
      };
    },
    popinPane(state, action) {
      return {
        ...state,
        popoutPanes: state.popoutPanes.filter(
          (url) => url !== action.payload.url,
        ),
      };
    },
    overwrite(state, action) {
      return action.payload;
    },
  },
  extraReducers(builder) {
    builder.addDefaultCase((state, action: AnyAction) => {
      switch (action.type) {
        case EditorConstants.OVERWRITE: {
          return action.payload.paneSplitter;
        }
        default: {
          return state;
        }
      }
    });
  },
});

export const paneSplitterActions = paneSplitterSlice.actions;

export type State = typeof initialState;
export default paneSplitterSlice.reducer;
