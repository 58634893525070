import * as React from 'react';
import Viewer from './projects/edit/Viewer';
import { withEditorTheme } from './withEditorTheme';
import { withRootTheme } from './withRootTheme';

export function PoppedOutEditorViewerInner({
  compiling,
  result,
  texDocSearching,
  documentResult,
  pdfPageInfo,
  showSynctexPdfIndicator,
  tabValue,
  project,
  showPdfViewLoading,
  invalidateFileCacheHandler,
  openInvalidateFileCacheDialog,
  pdfLoading,
  onPdfLoaded,
  onPdfLoadStarted,
}) {
  return (
    <Viewer
      compiling={compiling}
      result={result}
      texDocSearching={texDocSearching}
      documentResult={documentResult}
      pdfPageInfo={pdfPageInfo}
      showSynctexPdfIndicator={showSynctexPdfIndicator}
      scrollSync={project?.scroll_sync}
      tabValue={tabValue}
      project={project}
      showPdfViewLoading={showPdfViewLoading}
      invalidateFileCacheHandler={invalidateFileCacheHandler}
      openInvalidateFileCacheDialog={openInvalidateFileCacheDialog}
      pdfLoading={pdfLoading}
      onPdfLoaded={onPdfLoaded}
      onPdfLoadStarted={onPdfLoadStarted}
    />
  );
}

export const PoppedOutEditorViewer = withRootTheme(
  withEditorTheme(PoppedOutEditorViewerInner),
);
